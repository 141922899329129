import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import rehypeReact from "rehype-react";

import Carousel, { Modal, ModalGateway } from "react-images";

import { Button } from "gatsby-theme-material-ui";
import Grid from "@material-ui/core/Grid";
import Gallery from "react-photo-gallery";
import TextInfoContent from "@mui-treasury/components/content/textInfo";

import Layout from "../components/Layout";
import Container from "../components/Container";

const renderAst = new rehypeReact({
    createElement: React.createElement,
}).Compiler;

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "2em",
    },
    body: {
        textAlign: "justify",
    },
    action: {
        display: "flex",
        justifyContent: "center",
        maxWidth: "200px",
    },
}));

const StaysPrivatePage = ({
    data: {
        allMarkdownRemark: { edges: content },
        galleryContent: { edges: gallery },
    },
}) => {
    const styles = useStyles();

    const photos = gallery.map((img) => ({
        id: img.node.childImageSharp.id,
        width: img.node.childImageSharp.gatsbyImageData.width,
        height: img.node.childImageSharp.gatsbyImageData.height,
        src: img.node.childImageSharp.gatsbyImageData.images.fallback.src,
        srcSet: img.node.childImageSharp.gatsbyImageData.images.fallback.srcSet,
        sizes: img.node.childImageSharp.gatsbyImageData.images.fallback.sizes,
    }));

    const [currentImage, setCurrentImage] = React.useState(0);
    const [viewerIsOpen, setViewerIsOpen] = React.useState(false);

    const openLightbox = React.useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    return (
        <Layout pageName="daytrips">
            <Helmet>
                <title>Villas and Lofts in Valle de Guadalupe</title>
            </Helmet>
            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                            currentIndex={currentImage}
                            views={photos.map((x) => ({
                                ...x,
                                srcset: x.srcSet,
                                caption: x.title,
                            }))}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
            <Container maxWidth="md" type="content">
                <Grid container className={styles.root} spacing={4}>
                    {content.map((c) => (
                        <>
                            {/* xs, sm, md, lg, and xl. */}
                            <Grid item xs={12} md={6} key={`g-content`}>
                                <TextInfoContent
                                    bodyProps={{ className: styles.body }}
                                    overline={c.node.frontmatter.overline}
                                    heading={c.node.frontmatter.title}
                                    body={renderAst(c.node.htmlAst)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} key={`g-image`}>
                                <Gallery columns={2} direction={`column`} photos={photos} onClick={openLightbox} />
                            </Grid>
                            <Grid item xs={12} md={12} key={`g-action`}>
                                <Button
                                    className={styles.action}
                                    size="large"
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                        window.$chatwoot.reset();
                                        window.$chatwoot.setCustomAttributes({
                                            inquiry: "stays",
                                        });
                                        window.$chatwoot.toggle("open");
                                    }}
                                >
                                    {c.node.frontmatter.actionTitle}
                                </Button>
                            </Grid>
                        </>
                    ))}
                </Grid>
            </Container>
        </Layout>
    );
};

export default StaysPrivatePage;

export const StaysPrivatePageQuery = graphql`
    query StaysPrivatePageQuery {
        galleryContent: allFile(
            filter: { sourceInstanceName: { eq: "stays-gallery-private" } }
        ) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData
                    }
                    name
                }
            }
        }
        allMarkdownRemark(
            filter: { frontmatter: { type: { eq: "page-stays-private" } } }
            limit: 1
        ) {
            edges {
                node {
                    htmlAst
                    frontmatter {
                        title
                        actionTitle
                        overline
                        url
                    }
                }
            }
        }
    }
`;
